@import '@/styles/breakpoints.module';
@import '@/styles/typography.module';
@import '@/styles/button.module';
@import '@/blocks/components/SparklineChart/styles.module';

.tabs.tabs {
  :global(.MuiTabs-root) {
    margin-bottom: 24px;
    @include tablet {
      margin-bottom: 32px;
    }
    @include desktop {
      margin-bottom: 40px;
    }
  }

  :global(.MuiTabPanel-root) {
    padding: 0;
  }
}

.actionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;

  @include tablet {
    margin-top: 32px;
  }
  @include desktop {
    margin-top: 40px;
  }

  & > *:not(:first-child) {
    margin-inline-start: 16px;
  }
}
